import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const ServiceDetail = ({}) => {
  const [bannerImage, setBannerImage] = useState([]);
  const [existingImage, setExistingImage] = useState("");
  const [userData, setUserData] = useState({
    categoryName: "",
    heading: "",
    title: "",
    description: "",
    client: "",
    projectDate: null,
    referenceLink: "",
    videoUrl: "",
  });
  const { id } = useParams();

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/service/banner/show-all");
      setBannerImage(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      try {
        const response = await axios.get(`/api/service/get-single-item/${id}`);
        const itemData = response.data;

        setUserData({
          title: itemData.title,
          heading: itemData.heading,
          description: itemData.description,
          categoryName: itemData.categoryName,
          iconClass: itemData.iconClass,
        });
        console.log(itemData);
        setExistingImage(`/uploads/${itemData.image}`);
      } catch (error) {
        console.log(error);
      }
    };
    loadItems();
  }, [id]);

  const bannerStyle = {
    backgroundImage: `url(/uploads/${
      bannerImage.length > 0 ? bannerImage[0].image : ""
    })`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  return (
    <>
      <div class="inner-banner" style={bannerStyle}>
        <div class="container">
          <h3>Service Details</h3>
          <ul class="breadcumb">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <span class="sep">
                <i class="fa fa-angle-right"></i>
              </span>
            </li>
            <li>
              <span>Service Details</span>
            </li>
          </ul>
        </div>
      </div>

      <section class="project-details">
        <div class="container">
          <div class="row">
            <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12">
              <img
                className="img-fluid"
                src={existingImage}
                alt={userData.title}
              />
            </div>

            <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12">
              <div class="project-details-content">
                <h3>{userData.title}</h3>
                <br />
                <p>{userData.description}</p>
                <br />
                <ul class="meta-info">
                  <li>
                    <i class="fa fa-tag"></i>
                    <span>Category:</span>
                    {userData.categoryName}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceDetail;
