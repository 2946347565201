import React, { useEffect, useState } from "react";
import axios from "axios";
import GetInTouch from "./Common/GetInTouch";
import HistoryWork from "./Common/HistoryWork";
import { Link } from "react-router-dom";
const AboutUs = () => {
  const [bannerImage, setBannerImage] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [features, setFeaturesData] = useState([]);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/about/header/show-all");
      setHeaderData(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/about/banner/show-all");
      setBannerImage(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/about/features/show-all");
      setFeaturesData(data);
      console.log(data);
      console.log(data[0].iconClass);
    };
    loadItems();
  }, []);

  const bannerStyle = {
    backgroundImage: `url(/uploads/${
      bannerImage.length > 0 ? bannerImage[0].image : ""
    })`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  return (
    <>
      <div className="inner-banner" style={bannerStyle}>
        <div className="container">
          <h3>About Us</h3>
          <ul className="breadcumb">
            <li>
              <Link to="/">Home</Link>
            </li>
            {/*
             */}
            <li>
              <span className="sep">
                <i className="fa fa-angle-right" />
              </span>
            </li>
            {/*
             */}
            <li>
              <span>About Us</span>
            </li>
          </ul>
          {/* /.breadcumb */}
        </div>
        {/* /.container */}
      </div>
      {/* /.inner-banner */}

      <section className="about-style-three">
        <div className="container">
          {headerData.length > 0 ? (
            <div className="row">
              <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="about-img-box col-9">
                  <img
                    src={`/uploads/${headerData[0].image}`}
                    alt={`/uploads/${headerData[0].image}`}
                    className="img-fluid"
                  />
                  {/* /.content */}
                </div>
                {/* /.about-img-box */}
              </div>
              {/* /.col-md-6 */}
              <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="about-content">
                  <h3>{headerData[0].title}</h3>
                  <p>{headerData[0].description}</p>
                  <Link to="/projects" className="about-btn hvr-sweep-to-right">
                    Read More
                  </Link>
                </div>
                {/* /.about-content */}
              </div>
              {/* /.col-md-6 */}
            </div>
          ) : (
            ""
          )}
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /.about-style-three */}

      <section className="features-style-two">
        <div className="container">
          <div className="row">
            {/* /.col-md-4 col-sm-6 col-xs-12 */}
            {features?.map((d) => (
              <div key={d._id} className="col-md-4 col-sm-6 col-xs-12">
                <div className="single-features-style-two hvr-bounce-to-bottom">
                  <div className="inner">
                    <i className={d.iconClass}></i>
                    <h3>{d.heading.substring(0, 65)}</h3>
                    <p>{d.description.substring(0, 190)}</p>
                  </div>
                  {/* /.inner */}
                </div>
                {/* /.single-features-style-two */}
              </div>
            ))}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>

      {/* /.features-style-two */}
      <HistoryWork />
      {/* /.time-line */}

      {/* /.service-style-four */}
      <GetInTouch />
      {/* /.contact-info-style-one */}
    </>
  );
};

export default AboutUs;
