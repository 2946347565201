import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const ServiceSlider = ({ services }) => {
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Adjust the number of slides shown as needed
    autoplay: true, // Auto-slide enabled
    autoplaySpeed: 3000, // Set the autoplay interval (in milliseconds)
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2, // Adjust for smaller screens
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1, // Adjust for mobile screens
        },
      },
    ],
  };

  return (
    <Slider {...sliderSettings}>
      {services.map((service) => (
        <div key={service.id} className="service-item">
          <Link to="/services" className="text-dark">
            <div className="service-content mt-5 mb-0">
              <div className="service-icon">
                <i className={service.iconClass} />
              </div>
              <div className="service-text">
                <h3 className="text-center">
                  {service.heading.substring(0, 10)}...
                </h3>
                <p className="text-center">
                  {service.description.substring(0, 10)}...
                </p>
              </div>
            </div>
            <div className="service-details">
              <h3 className="text-center">
                {service.heading.substring(0, 20)}...
              </h3>
              <p className="text-center">
                {service.description.substring(0, 20)}...
              </p>
              <Link to="/services" className="text-white text-center">
                Read More
              </Link>
            </div>
          </Link>
        </div>
      ))}
    </Slider>
  );
};

export default ServiceSlider;
