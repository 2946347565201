import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Service = () => {
  const [bannerImage, setBannerImage] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All Services");

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/service/show-all");
      setServices(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/service/banner/show-all");
      setBannerImage(data);
      console.log(data);
    };
    loadItems();
  }, []);

  const bannerStyle = {
    backgroundImage: `url(/uploads/${
      bannerImage.length > 0 ? bannerImage[0].image : ""
    })`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  const groupServicesByCategory = (services) => {
    return services.reduce((grouped, service) => {
      if (!grouped[service.categoryName]) {
        grouped[service.categoryName] = [];
      }
      grouped[service.categoryName].push(service);
      return grouped;
    }, {});
  };

  const groupedServices = groupServicesByCategory(services);

  const filterServices = (category) => {
    setSelectedCategory(category);
  };

  const filteredServices =
    selectedCategory === "All Services"
      ? services
      : services.filter((service) => service.categoryName === selectedCategory);

  return (
    <>
      <div className="inner-banner" style={bannerStyle}>
        <div className="container">
          <h3>Service Page</h3>
          <ul className="breadcumb">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <span className="sep">
                <i className="fa fa-angle-right"></i>
              </span>
            </li>
            <li>
              <span>Service Page</span>
            </li>
          </ul>
        </div>
      </div>

      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-md-3 col-sm-12 col-xs-12">
            <div className="sidebar">
              <div className="single-sidebar service-sidebar">
                <ul className="service-list">
                  <li
                    className={`"filter ${
                      selectedCategory === "All Services" ? "active" : ""
                    }`}
                    onClick={() => filterServices("All Services")}
                  >
                    {" "}
                    <Link>All Services</Link>{" "}
                  </li>
                  {Object.keys(groupedServices)?.map((categoryName) => (
                    <li
                      className={
                        selectedCategory === categoryName ? "active" : ""
                      }
                      onClick={() => filterServices(categoryName)}
                      key={categoryName}
                    >
                      {" "}
                      <Link>{categoryName.substring(0, 55)}...</Link>{" "}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-9 col-sm-12 col-xs-12">
            <div className="service-item-box">
              <div className="row">
                {filteredServices?.map((d) => (
                  <div key={d._id} className="col-md-4 col-sm-6 col-sm-12 mt-3">
                    <div className="gas single-service-style-four">
                      <Link to={`/service-detail/${d._id}`}>
                        <div className="img-box">
                          <img
                            className="img-fluid"
                            src={`/uploads/${d.image}`}
                            alt={d.image}
                            style={{
                              height: "255px",
                              objectFit: "cover",
                              transition: "filter 0.3s ease", // Add transition for smooth effect
                            }}
                          />
                          <div className="box">
                            <div style={{ marginTop: "210px" }}>
                              {/* <i className={d.iconClass}></i> */}
                              <div className="service-title-heading-bg-color p-1 mb-2 ms-3">
                                <span className="fs-2 fw-bold text-dark">
                                  {d.heading.substring(0, 45)}...
                                </span>{" "}
                                <br />{" "}
                                <p className="fs-3 text-dark mb-0">
                                  {d.title.substring(0, 55)}...
                                </p>
                              </div>
                              <Link
                                to={`/service-detail/${d._id}`}
                                className="more hvr-sweep-to-right ms-3 pe-2 ps-2 text-white"
                                style={{
                                  backgroundColor: "#11438e",
                                  transition: "background-color 0.3s ease", // Add transition for smooth background color change
                                }}
                              >
                                Learn more
                              </Link>
                            </div>
                          </div>
                        </div>
                        {/* Apply hover effect here */}
                        <style>
                          {`
            .img-box:hover img {
              filter: blur(5px); // Adjust the blur amount as needed
            }
          `}
                        </style>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
