import React, { useState } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Input from "../../../common/Input";
import Button from "../../../common/Button";

const Add = () => {
  const route = "/api/home/brand/add";
  const [image, setImage] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(false);

  // error messages
  const created = "Created Successfully";
  const errorMessage = "something Bad Happend";
  const imageError = "image is missing";
  const imageSizeError = "Please choose file less than 5 MB";

  const notifyCreate = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
      setFileSizeError(true);
      try {
        notifyError(imageSizeError);
      } catch (error) {
        console.log(error);
      }
    } else {
      setFileSizeError(false);
      setImage(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image);

    if (!fileSizeError) {
      switch (true) {
        case !image:
          notifyError(imageError);
          break;

        default:
          try {
            // send a POST request to the server to add the product
            const response = await axios.post(route, formData);
            notifyCreate(created);
            // handle the response and perform any necessary actions
            console.log(response);
            console.log(response.data);

            // reset the form
          } catch (error) {
            notifyError(errorMessage);
            console.error(error);
          }
          break;
      }
    }
  };

  return (
    <div>
      <h3 className="text-center">Add Brand Image</h3>
      <form onSubmit={handleSubmit}>
        <Input
          type="file"
          onChange={handleImageChange}
          id="floatingInputImage"
          placeholder="Image"
          label="Image - Please select file less than 5 MB - 170x100"
        />
        <div className="text-center">
          <Button name="Submit" type="submit" className="btn btn-primary" />
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Add;
