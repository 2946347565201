import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const GetInTouch = () => {
  const [touchdata, setTouchData] = useState([]);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/get-in-touch/show-all");
      setTouchData(data);
      console.log(data);
    };
    loadItems();
  }, []);

  return (
    <>
      <section className="contact-info-style-one">
        <div className="container">
          {touchdata.length > 0 ? (
            <div className="row">
              <div className="col-md-5">
                <div className="title">
                  <h3>{touchdata[0].heading}</h3>
                  <p>
                    {touchdata[0].description.substring(0, 130)}...
                    <Link to="/contact-us">Read More</Link>
                  </p>
                </div>
                {/* /.title */}
              </div>
              {/* /.col-md-7 */}
              <div className="col-md-7">
                <div className="contact-infos">
                  <div className="single-contact-infos">
                    <div className="icon-box">
                      <i className="industrio-icon-phone-call" />
                    </div>
                    {/* /.icon-box */}
                    <div className="text-box">
                      <h3>Call us on</h3>
                      <p>{touchdata[0].phone}</p>
                    </div>
                    {/* /.text-box */}
                  </div>
                  {/* /.single-contact-infos */}
                  <div className="single-contact-infos">
                    <div className="icon-box">
                      <i className="industrio-icon-envelope" />
                    </div>
                    {/* /.icon-box */}
                    <div className="text-box">
                      <h3>Email us</h3>
                      <p>{touchdata[0].email}</p>
                    </div>
                    {/* /.text-box */}
                  </div>
                  {/* /.single-contact-infos */}
                </div>
                {/* /.contact-infos */}
              </div>
              {/* /.col-md-5 */}
            </div>
          ) : (
            ""
          )}
          {/* /.row */}
        </div>
        {/* /.contianer */}
      </section>
    </>
  );
};

export default GetInTouch;
