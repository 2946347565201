import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Support from "./Common/Support";
import GetInTouch from "./Common/GetInTouch";

const ContactUs = () => {
  const [bannerImage, setBannerImage] = useState([]);
  const [headerData, setHeaderData] = useState([]);

  // for contact form
  const [yourName, setYourName] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [message, setMessage] = useState("");

  // error messages
  const created = "Message Sent Successfully";
  const errorMessage = "something Bad Happend";
  const yourNameError = "Name is missing";
  const emailError = "Email is missing";
  const locationError = "Location is missing";
  const messageError = "Message is missing";

  const notifyCreate = (message) =>
    toast.success(message, { position: "bottom-right" });

  const notifyError = (message) =>
    toast.error(message, { position: "bottom-right" });

  const handleYourNameChange = (e) => {
    setYourName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    switch (true) {
      case !yourName:
        notifyError(yourNameError);
        break;

      case !email:
        notifyError(emailError);
        break;

      case !location:
        notifyError(locationError);
        break;

      case !message:
        notifyError(messageError);
        break;

      default:
        try {
          // send a POST request to the server to add the product
          const response = await axios.post("/api/contact-us/form/add", {
            yourName,
            email,
            location,
            message,
          });
          notifyCreate(created);
          // handle the response and perform any necessary actions
          console.log(response);
          console.log(response.data);

          // reset the form
          setYourName("");
          setEmail("");
          setLocation("");
          setMessage("");
        } catch (error) {
          notifyError(errorMessage);
          console.error(error);
        }
        break;
    }
  };

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/contact-us/header/show-all");
      setHeaderData(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/contact-us/banner/show-all");
      setBannerImage(data);
      console.log(data);
    };
    loadItems();
  }, []);

  const bannerStyle = {
    backgroundImage: `url(/uploads/${
      bannerImage.length > 0 ? bannerImage[0].image : ""
    })`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  return (
    <>
      <div className="inner-banner" style={bannerStyle}>
        <div className="container">
          <h3>Contact Us</h3>
          <ul className="breadcumb">
            <li>
              <Link to="/">Home</Link>
            </li>
            {/*
             */}
            <li>
              <span className="sep">
                <i className="fa fa-angle-right" />
              </span>
            </li>
            {/*
             */}
            <li>
              <span>Contact Us</span>
            </li>
          </ul>
          {/* /.breadcumb */}
        </div>
        {/* /.container */}
      </div>
      {/* /.inner-banner */}

      <section className="contact-page sec-pad">
        <div className="container">
          {headerData.length > 0 ? (
            <div className="sec-title text-center">
              <h3>{headerData[0].heading}</h3>
              <p>{headerData[0].description}</p>
            </div>
          ) : (
            ""
          )}
          {/* /.sec-title text-center */}
          <div className="container">
            <div className="row d-flex justify-content-between">
              <div className="col-md-6 col-md-12 p-1 mt-5 pt-5">
                <iframe
                  title="Voltonic Systems's Location"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3465.586829451022!2d-95.84377490000001!3d29.702756299999987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8641224db107dce9%3A0x3916d78e18260c08!2s6138%20Sierra%20Springs%20Ln%2C%20Katy%2C%20TX%2077494%2C%20USA!5e0!3m2!1sen!2s!4v1707806790839!5m2!1sen!2s"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  className="col-12"
                  height={465}
                ></iframe>
              </div>
              <div className="col-md-6 col-md-12 p-5 mt-3">
                <form onSubmit={handleSubmit} className="contact-form">
                  <h3>Send Mail</h3>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Your name.."
                    name="name"
                    value={yourName}
                    onChange={handleYourNameChange}
                  />
                  <input
                    className="form-control"
                    type="email"
                    placeholder="Your email address.."
                    name="email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Your Location.."
                    name="subject"
                    value={location}
                    onChange={handleLocationChange}
                  />
                  <textarea
                    placeholder="Write..."
                    className="form-control"
                    name="message"
                    value={message}
                    onChange={handleMessageChange}
                  />
                  <button type="submit" className="hvr-sweep-to-right">
                    Send
                  </button>
                </form>
                <ToastContainer />
              </div>
              {/* /.col-md-6 */}
            </div>
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /.contact-page */}

      <Support />
      {/* /.feature-style-one */}

      <GetInTouch />
      {/* /.contact-info-style-one */}
    </>
  );
};

export default ContactUs;
