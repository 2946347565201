import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { currentYear } from "../../utils";

const NavbarNew = () => {
  const [touchdata, setTouchData] = useState([]);
  const [logo, setLogo] = useState([]);
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [projectsBottom, setProjectsBottom] = useState([]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/get-in-touch/show-all");
      setTouchData(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/logo/show-all");
      setLogo(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/project/bottom/show-all");
      setProjectsBottom(data);
      console.log(data);
    };
    loadItems();
  }, []);

  return (
    <>
      <div className="header-top home-three">
        <div className="top-info">
          <div className="container-fluid">
            <div className="pull-left left-text">
              <p>
                Welcome to <span className="fw-bold">Voltonic Systems</span>
              </p>
            </div>
            {/* /.pull-left */}
            <div className="pull-right social">
              {projectsBottom.length > 0 ? (
                <>
                  {projectsBottom[0].facebook !== "" ? (
                    <Link
                      target="_blank"
                      href={projectsBottom[0].facebook}
                      className="fab fa-facebook"
                    />
                  ) : (
                    ""
                  )}
                  {projectsBottom[0].twitter !== "" ? (
                    <Link
                      target="_blank"
                      href={projectsBottom[0].twitter}
                      className="fab fa-twitter-square"
                    />
                  ) : (
                    ""
                  )}
                  {projectsBottom[0].instagram !== "" ? (
                    <Link
                      target="_blank"
                      href={projectsBottom[0].instagram}
                      className="fab fa-instagram"
                    />
                  ) : (
                    ""
                  )}
                  {projectsBottom[0].linkedin !== "" ? (
                    <Link
                      target="_blank"
                      href={projectsBottom[0].linkedin}
                      className="fab fa-linkedin"
                    />
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </div>
            {/* /.pull-right */}
          </div>
          {/* /.container */}
        </div>
        {/* /.top-info */}
        <div className="container">
          <div className="logo pull-left">
            {logo.length > 0 && (
              <Link to="/">
                <img
                  className="img-fluid"
                  src={`/uploads/${logo[0].image}`}
                  alt="Voltonic Systems logo"
                  style={{ width: "150px" }}
                />
              </Link>
            )}
          </div>
          {/* /.logo */}
          {touchdata.length > 0 && (
            <div className="header-right-info pull-right">
              <div className="single-header-right-info">
                <div className="icon-box">
                  <i className="industrio-icon-phone-call" />
                </div>
                {/* /.icon-box */}
                <div className="text-box">
                  <p>Call us 9:00am - 5:00pm</p>
                  <h3>{touchdata[0].phone}</h3>
                </div>
                {/* /.text-box */}
              </div>
              {/* /.single-header-right-info */}
              <div className="single-header-right-info">
                <div className="icon-box">
                  <i className="industrio-icon-clock" />
                </div>
                {/* /.icon-box */}
                <div className="text-box">
                  <p>Monday to Friday</p>
                  <h3>9:00am - 5:00pm</h3>
                </div>
                {/* /.text-box */}
              </div>
              {/* /.single-header-right-info */}
              <div className="single-header-right-info">
                <div className="icon-box">
                  <i className="industrio-icon-envelope" />
                </div>
                {/* /.icon-box */}
                <div className="text-box navbar__email-box navbar__email-wrap">
                  <p>E-mail us</p>
                  <h3 className="fs-2">{touchdata[0].email}</h3>
                </div>
                {/* /.text-box */}
              </div>
              {/* /.single-header-right-info */}
            </div>
          )}
          {/* /.header-right-info */}
        </div>
        {/* /.container */}
      </div>
      {/* /.header-top home-one */}
      <header className="header header-home-three">
        <nav className="navbar navbar-default header-navigation">
          <div className="container clearfix">
            {/* Brand and toggle get grouped for better mobile display */}
            <div className="navbar-header">
              <button
                className="side-nav-toggler side-nav-opener"
                onClick={toggleMenu}
              >
                <i className="fa fa-bars" />
              </button>
            </div>
            {/* Collect the nav links, forms, and other content for toggling */}
            <div
              className="collapse navbar-collapse main-navigation mainmenu "
              id="main-nav-bar"
            >
              <ul className="nav navbar-nav navigation-box">
                <li className={location.pathname === "/" ? "current" : ""}>
                  <NavLink exact to="/">
                    Home
                  </NavLink>
                </li>
                <li
                  className={location.pathname === "/about-us" ? "current" : ""}
                >
                  <Link to="/about-us">About Us</Link>{" "}
                </li>
                <li
                  className={location.pathname === "/services" ? "current" : ""}
                >
                  <Link to="/services">Services</Link>
                </li>
                <li
                  className={location.pathname === "/projects" ? "current" : ""}
                >
                  <Link to="/projects">Projects</Link>
                </li>
              </ul>
            </div>
            {/* /.navbar-collapse */}
            <div className="right-side-box">
              <Link to="/contact-us" className="rqa-btn">
                <span className="inner">
                  <Link to="/contact-us">
                    <span className="text-white">Contact Us</span>{" "}
                    <i className="fa fa-caret-right text-white" />
                  </Link>
                </span>
              </Link>
            </div>
            {/* /.right-side-box */}
          </div>
          {/* /.container */}
        </nav>
      </header>

      <section
        className={`hidden-sidebar ${isMenuOpen ? "open" : ""} side-navigation`}
      >
        <bubtton
          className="close-button btn btn-link text-decoration-none side-navigation-close-btn fa fa-times"
          onClick={toggleMenu}
        ></bubtton>
        {/* /.close-button */}
        <div className="sidebar-content">
          <div className="top-content">
            {logo.length > 0 && (
              <Link to="/">
                <img
                  className="img-fluid"
                  src={`/uploads/${logo[0].image}`}
                  alt="Voltonic Systems logo"
                  style={{ width: "200px" }}
                  onClick={toggleMenu}
                />
              </Link>
            )}
          </div>
          {/* /.top-content */}
          <nav className="nav-menu middle-content">
            <ul class="navigation-box">
              <li class="current">
                <Link to="/" onClick={toggleMenu}>
                  Home <span class="subnav-toggler"></span>
                </Link>
              </li>
              <li>
                <Link onClick={toggleMenu} to="/about-us">
                  About Us
                </Link>
              </li>
              <li>
                <Link onClick={toggleMenu} to="/services">
                  Services <span class="subnav-toggler"></span>
                </Link>
              </li>
              <li>
                <Link onClick={toggleMenu} to="/projects">
                  Projects <span class="subnav-toggler"></span>
                </Link>
              </li>
              <li>
                <Link onClick={toggleMenu} to="/contact-us">
                  Contact Us
                </Link>{" "}
              </li>
              <li>
                <bubtton
                  className="close-button btn btn-link text-white text-decoration-none side-navigation-close-btn fa fa-times"
                  onClick={toggleMenu}
                ></bubtton>
              </li>
            </ul>
          </nav>
          {/* /.nav-menu */}
          <div class="bottom-content">
            {projectsBottom.length > 0 ? (
              <div className="social">
                {projectsBottom[0].facebook !== "" ? (
                  <Link
                    target="_blank"
                    href={projectsBottom[0].facebook}
                    className="fab fa-facebook"
                  />
                ) : (
                  ""
                )}
                {projectsBottom[0].twitter !== "" ? (
                  <Link
                    target="_blank"
                    href={projectsBottom[0].twitter}
                    className="fab fa-twitter-square"
                  />
                ) : (
                  ""
                )}
                {projectsBottom[0].instagram !== "" ? (
                  <Link
                    target="_blank"
                    href={projectsBottom[0].instagram}
                    className="fab fa-instagram"
                  />
                ) : (
                  ""
                )}
                {projectsBottom[0].linkedin !== "" ? (
                  <Link
                    target="_blank"
                    href={projectsBottom[0].linkedin}
                    className="fab fa-linkedin"
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            <p class="copy-text">
              &copy; {currentYear} Voltonic Systems. <br /> made with{" "}
              <i class="fa fa-heart"></i> by Voltonic Systems Tech Team
            </p>
          </div>
          {/* /.bottom-content */}
        </div>
        {/* /.sidebar-content */}
      </section>
      {/* /.hidden-sidebar */}
    </>
  );
};

export default NavbarNew;
