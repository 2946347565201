import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import GetInTouch from "./Common/GetInTouch";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Projects = () => {
  const [bannerImage, setBannerImage] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectsBottom, setProjectsBottom] = useState([]);
  const [projectsheadingData, setprojectsHeadingData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All Projects");
  const sliderRef = useRef();

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 7, // Number of categories to show at a time
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 5, // Number of categories to show on mobile screens
        },
      },
      {
        breakpoint: 768, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 4, // Number of categories to show on mobile screens
        },
      },
      {
        breakpoint: 425, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1, // Number of categories to show on mobile screens
        },
      },
      {
        breakpoint: 375, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1, // Number of categories to show on mobile screens
        },
      },
      {
        breakpoint: 320, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1, // Number of categories to show on mobile screens
        },
      },
    ],
  };

  const groupProjectsByCategory = (projects) => {
    return projects.reduce((grouped, project) => {
      if (!grouped[project.categoryName]) {
        grouped[project.categoryName] = [];
      }
      grouped[project.categoryName].push(project);
      return grouped;
    }, {});
  };

  const groupedProjects = groupProjectsByCategory(projects);

  const filterProjects = (category) => {
    setSelectedCategory(category);
  };

  const filteredProjects =
    selectedCategory === "All Projects"
      ? projects
      : projects.filter((project) => project.categoryName === selectedCategory);

  const handleNextClick = () => {
    sliderRef.current.slickNext();
  };

  const handlePrevClick = () => {
    sliderRef.current.slickPrev();
  };

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/project/banner/show-all");
      setBannerImage(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/home-projects/header/show-all");
      setprojectsHeadingData(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/project/show-all");
      setProjects(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/project/bottom/show-all");
      setProjectsBottom(data);
      console.log(data);
    };
    loadItems();
  }, []);

  const bannerStyle = {
    backgroundImage: `url(/uploads/${
      bannerImage.length > 0 ? bannerImage[0].image : ""
    })`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  const bgImageStyle = {
    backgroundImage: `url(/uploads/${
      projectsBottom.length > 0 ? projectsBottom[0].image : ""
    })`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    height: "auto",
  };

  return (
    <>
      <div className="inner-banner" style={bannerStyle}>
        <div className="container">
          <h3>Project Page</h3>
          <ul className="breadcumb">
            <li>
              <Link to="/">Home</Link>
            </li>
            {/*
             */}
            <li>
              <span className="sep">
                <i className="fa fa-angle-right" />
              </span>
            </li>
            {/*
             */}
            <li>
              <span>Project Page</span>
            </li>
          </ul>
          {/* /.breadcumb */}
        </div>
        {/* /.container */}
      </div>
      {/* /.inner-banner */}

      <div className="container-fluid">
        <section className="portfolio-style-two sec-pad">
          <div className="container">
            {projectsheadingData.length > 0 ? (
              <div className="sec-title text-center">
                <h3>{projectsheadingData[0].heading}</h3>
                <p>{projectsheadingData[0].description}</p>
              </div>
            ) : (
              ""
            )}
            {/* /.sec-title */}
            <div className="gallery-filter">
              <div className="d-flex justify-content-center mb-2">
                <button
                  className="badge bg-primary border-0 me-3"
                  onClick={handlePrevClick}
                >
                  &lt;
                </button>
                <button
                  className="badge bg-primary border-0"
                  onClick={handleNextClick}
                >
                  &gt;
                </button>
              </div>
              <ul className="text-center bg-light border border-2 rounded-pill shadow-lg  ">
                <Slider ref={sliderRef} {...settings}>
                  <li
                    className={`"filter ${
                      selectedCategory === "All Projects"
                        ? "active projects__category-button-bg-color rounded-pill"
                        : ""
                    }`}
                    onClick={() => filterProjects("All Projects")}
                  >
                    <span
                      className={`${
                        selectedCategory === "All Projects"
                          ? "text-white"
                          : "text-dark"
                      }`}
                    >
                      <i className="industrio-icon-layers" />
                      All Projects
                    </span>
                  </li>
                  {/*
                   */}
                  {Object.keys(groupedProjects)?.map((categoryName) => (
                    <li
                      className={
                        selectedCategory === categoryName
                          ? "active projects__category-button-bg-color rounded-pill shadow text-white"
                          : ""
                      }
                      onClick={() => filterProjects(categoryName)}
                      key={categoryName}
                    >
                      <span
                        className={`${
                          selectedCategory === categoryName
                            ? "text-white"
                            : "text-dark"
                        }`}
                      >
                        {/* <i className="industrio-icon-drop-of-liquid" /> */}
                        {categoryName.substring(0, 20)}...
                      </span>
                    </li>
                  ))}
                  {/*
                   */}
                </Slider>
              </ul>
              {/* /.post-filter */}
            </div>
            {/* /.gallery-filter */}
            <div className="container">
              <div className="row">
                {filteredProjects?.map((d) => (
                  <div
                    className={`col-md-3 col-sm-6 col-xs-12 single-filter-item construction ${d.categoryName}`}
                    key={d._id}
                  >
                    <Link to={`/project-detail/${d._id}`}>
                      <div className="single-portfolio-style-two">
                        <div className="img-box">
                          <img
                            src={`/uploads/${d.image}`}
                            alt="Awesome Image"
                            className="img-fluid"
                            style={{ height: "200px", objectFit: "cover" }}
                          />
                          <div className="overlay">
                            <div className="box">
                              <div className="content">
                                <span className="fs-3">
                                  {d.title.substring(0, 40)}...
                                </span>
                                <Link to={`/project-detail/${d._id}`}>
                                  <h3 className="fs-3">
                                    {d.heading.substring(0, 20)}...
                                  </h3>
                                </Link>
                                <Link
                                  to={`/project-detail/${d._id}`}
                                  className="img-popup industrio-icon-next"
                                />
                              </div>
                              {/* /.content */}
                            </div>
                            {/* /.box */}
                          </div>
                          {/* /.overlay */}
                        </div>
                        {/* /.img-box */}
                      </div>
                      {/* /.single-portfolio-style-two */}
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* /.container */}
        </section>
      </div>
      {/* /.project-style-one */}
      <section className="contact-info-style-two" style={bgImageStyle}>
        {projectsBottom.length > 0 ? (
          <div className="container-fluid">
            <div className="col-md-6">
              <div className="left-content">
                <div className="inner">
                  <h3>{projectsBottom[0].title}</h3>
                  <p>{projectsBottom[0].description}</p>
                  <h4>Get Connected With Us</h4>
                  <div className="social">
                    {projectsBottom[0].facebook !== "" ? (
                      <Link
                        target="_blank"
                        href={projectsBottom[0].facebook}
                        className="fab fa-facebook"
                      />
                    ) : (
                      ""
                    )}
                    {projectsBottom[0].twitter !== "" ? (
                      <Link
                        target="_blank"
                        href={projectsBottom[0].twitter}
                        className="fab fa-twitter-square"
                      />
                    ) : (
                      ""
                    )}
                    {projectsBottom[0].instagram !== "" ? (
                      <Link
                        target="_blank"
                        href={projectsBottom[0].instagram}
                        className="fab fa-instagram"
                      />
                    ) : (
                      ""
                    )}
                    {projectsBottom[0].linkedin !== "" ? (
                      <Link
                        target="_blank"
                        href={projectsBottom[0].linkedin}
                        className="fab fa-linkedin"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  {/* /.social */}
                </div>
                {/* /.inner */}
              </div>
              {/* /.left-content */}
            </div>
            {/* /.col-md-6 */}
            {/* <div className="col-md-6"> */}
            {/* <div className="right-video-box"> */}
            {/* <a href="#" target="_blank" className="video-popup">
                  <i className="industrio-icon-play-button" /> Play Video
                </a> */}
            {/* </div> */}
            {/* /.right-video-box */}
            {/* </div> */}
            {/* /.col-md-6 */}
          </div>
        ) : (
          ""
        )}

        {/* /.container-fluid */}
      </section>
      {/* /.video-box-style-one */}

      <GetInTouch />
      {/* /.contact-info-style-one */}
    </>
  );
};

export default Projects;
