import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { currentYear } from "../../utils";

const Footer = () => {
  const [logo, setLogo] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [projectsBottom, setProjectsBottom] = useState([]);
  const [services, setServices] = useState([]);
  const [email, setEmail] = useState("");

  // error messages
  const created = "Subscribed Successfully";
  const errorMessage = "something Bad Happend";
  const emailError = "email is missing";

  const notifyCreate = (message) =>
    toast.success(message, { position: "bottom-right" });
  const notifyError = (message) => toast.error(message);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    switch (true) {
      case !email:
        notifyError(emailError);
        break;

      default:
        try {
          // send a POST request to the server to add the product
          const response = await axios.post("/api/subscribe/add", {
            email,
          });
          notifyCreate(created);
          // handle the response and perform any necessary actions
          console.log(response);
          console.log(response.data);

          // reset the form
          setEmail("");
        } catch (error) {
          notifyError(errorMessage);
          console.error(error);
        }
        break;
    }
  };

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/service/show-all");
      setServices(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/project/bottom/show-all");
      setProjectsBottom(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/about/header/show-all");
      setHeaderData(data);
      console.log(data);
    };
    loadItems();
  }, []);

  const getImageFilename = (imageUrl) => {
    const urlParts = imageUrl.split("/");
    const filename = urlParts[urlParts.length - 1]; // Get the last part which is the filename
    return filename;
  };
  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/logo/show-all");
      setLogo(data);
      console.log(data);
    };
    loadItems();
  }, []);

  const groupServicesByCategory = (services) => {
    return services.reduce((grouped, service) => {
      if (!grouped[service.categoryName]) {
        grouped[service.categoryName] = [];
      }
      grouped[service.categoryName].push(service);
      return grouped;
    }, {});
  };

  const groupedServices = groupServicesByCategory(services);

  return (
    <div className="container">
      <footer className="site-footer fixed-footer">
        <div className="main-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-6 col-xs-12">
                <div className="footer-widget about-widget">
                  {logo.length > 0 && (
                    <Link to="/">
                      <img
                        className="img-fluid"
                        src={`/uploads/${logo[0].image}`}
                        alt={getImageFilename(logo[0].image)}
                      />
                    </Link>
                  )}
                  <h3>About us</h3>
                  {headerData.length > 0 && (
                    <p>
                      {headerData[0].description.substring(0, 100)}
                      <Link to="/about-us">...Read More</Link>
                    </p>
                  )}
                </div>
                {/* /.footer-widget about-widget */}
              </div>
              {/* /.col-md-3 */}
              <div className="col-md-2 col-sm-6 col-xs-12">
                <div className="footer-widget links-widget">
                  <div className="title">
                    <h3>Links</h3>
                  </div>
                  {/* /.title */}
                  <ul className="links-list">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/projects">Project</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact</Link>
                    </li>
                  </ul>
                  {/* /.links-list */}
                </div>
                {/* /.footer-widget links-widget */}
              </div>
              {/* /.col-md-2 */}
              <div className="col-md-3 col-sm-6 col-xs-12">
                <div className="footer-widget services-widget">
                  <div className="title">
                    <h3>Services</h3>
                  </div>
                  {/* /.title */}
                  <ul className="links-list">
                    {Object.keys(groupedServices)?.map((categoryName) => (
                      <li key={categoryName + 1}>
                        <Link to="/services">
                          {categoryName.substring(0, 15)}...
                        </Link>
                      </li>
                    ))}
                  </ul>
                  {/* /.links-list */}
                </div>
                {/* /.footer-widget services-widget */}
              </div>
              {/* /.col-md-3 */}
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="footer-widget subscribe-widget">
                  <h3>Subscribe Today</h3>
                  <p>
                    {/* Many of our SELC registered employees are requested as main */}
                  </p>
                  <form onSubmit={handleSubmit} className="subscribe-form">
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={handleEmailChange}
                      placeholder="Email Address"
                    />
                    <button type="submit" className="hvr-sweep-to-right">
                      Subscribe
                    </button>
                  </form>
                  <ToastContainer />
                  {/* /.subscribe-form */}
                </div>
                {/* /.footer-widget subscribe-widget */}
              </div>
              {/* /.col-md-4 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* /.main-footer */}
        <div className="bottom-footer">
          <div className="container">
            <div className="left-text pull-left">
              <p>
                © Copyright Voltonic Systems {currentYear}. All right reserved.
              </p>
            </div>
            {/* /.left-text */}
            <div className="right-text pull-right">
              <p>Developed by: Voltonic Systems Tech Team</p>
            </div>
            {/* /.right-text */}
          </div>
          {/* /.container */}
        </div>
        {/* /.bottom-footer */}
      </footer>
      {/* /.site-footer */}
      <section className="hidden-sidebar side-navigation">
        <Link
          to="/"
          className="close-button side-navigation-close-btn fa fa-times"
        />
        {/* /.close-button */}
        <div className="sidebar-content">
          <div className="top-content">
            {logo.length > 0 && (
              <Link to="/">
                <img
                  className="img-fluid"
                  src={`/uploads/${logo[0].image}`}
                  alt={getImageFilename(logo[0].image)}
                  style={{ width: "15rem" }}
                />
              </Link>
            )}
          </div>
          {/* /.top-content */}
          <nav className="nav-menu middle-content">
            <ul className="navigation-box">
              <li className="current">
                <Link to="/">
                  Home <span className="subnav-toggler fa fa-caret-down" />
                </Link>
              </li>
              <li>
                <Link to="/">About Us</Link>{" "}
              </li>
              <li>
                <Link to="/services">
                  Services <span className="subnav-toggler fa fa-caret-down" />
                </Link>
              </li>
              <li>
                <Link to="/projects">
                  Projects <span className="subnav-toggler fa fa-caret-down" />
                </Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>{" "}
              </li>
            </ul>
          </nav>
          {/* /.nav-menu */}
          {projectsBottom.length > 0 ? (
            <div className="bottom-content">
              <div className="social">
                {projectsBottom[0].facebook && (
                  <a
                    target="_blank"
                    href={projectsBottom[0].facebook}
                    className="fab fa-facebook"
                    rel="noreferrer"
                  >
                    <span className="sr-only">Facebook</span>
                  </a>
                )}
                {projectsBottom[0].twitter && (
                  <a
                    target="_blank"
                    href={projectsBottom[0].twitter}
                    className="fab fa-twitter-square"
                    rel="noreferrer"
                  >
                    <span className="sr-only">Twitter</span>
                  </a>
                )}
                {projectsBottom[0].instagram && (
                  <a
                    target="_blank"
                    href={projectsBottom[0].instagram}
                    className="fab fa-instagram"
                    rel="noreferrer"
                  >
                    <span className="sr-only">Instagram</span>
                  </a>
                )}
                {projectsBottom[0].linkedin && (
                  <a
                    target="_blank"
                    href={projectsBottom[0].linkedin}
                    className="fab fa-linkedin"
                    rel="noreferrer"
                  >
                    <span className="sr-only">Linkedin</span>
                  </a>
                )}
              </div>
              {/* /.social */}
              <p className="copy-text">
                © {currentYear} Voltonic Systems <br /> made with{" "}
                <i className="fa fa-heart" /> by Voltonic Systems Tech Team
              </p>
              {/* /.copy-text */}
            </div>
          ) : (
            ""
          )}
          {/* /.bottom-content */}
        </div>
        {/* /.sidebar-content */}
      </section>
      {/* /.hidden-sidebar */}
      <div className="search_area zoom-anim-dialog mfp-hide" id="test-search">
        <div className="search_box_inner">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search for..."
            />
            <span className="input-group-btn">
              <button className="btn btn-default" type="button">
                <i className="fas fa-search" />
              </button>
            </span>
          </div>
        </div>
      </div>
      {/* <div className="scroll-to-top scroll-to-target" data-target="html">
        <i className="fa fa-angle-up" />
      </div> */}
    </div>
  );
};

export default Footer;
