import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import AboutUs from "./AboutUs";
import NavbarNew from "./NavbarNew";

const Website = ({ Component }) => {
  return (
    <div className="page-wrapper">
      {/* <Navbar /> */}
      <NavbarNew />
      <Component />
      <Footer />
    </div>
  );
};

export default Website;
