import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Support from "./Common/Support";
import { Link } from "react-router-dom";
import BrandSlider from "./BrandSlider";
import ServiceSlider from "./ServiceSlider";
import HomeSlider from "./Common/HomeSlider";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Home = () => {
  const [headerData, setHeaderData] = useState([]);
  const [projectsheadingData, setprojectsHeadingData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All Projects");
  const [headerPointsData, setHeaderPointsData] = useState([]);
  const [services, setServices] = useState([]);
  const [projects, setProjects] = useState([]);
  const [brands, setBrands] = useState([]);
  const [homeSlider, setHomeSlider] = useState([]);
  const sliderRef = useRef();

  const groupProjectsByCategory = (projects) => {
    return projects.reduce((grouped, project) => {
      if (!grouped[project.categoryName]) {
        grouped[project.categoryName] = [];
      }
      grouped[project.categoryName].push(project);
      return grouped;
    }, {});
  };

  const groupedProjects = groupProjectsByCategory(projects);

  const filterProjects = (category) => {
    setSelectedCategory(category);
  };

  const filteredProjects =
    selectedCategory === "All Projects"
      ? projects
      : projects.filter((project) => project.categoryName === selectedCategory);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 7, // Number of categories to show at a time
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 5, // Number of categories to show on mobile screens
        },
      },
      {
        breakpoint: 768, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 4, // Number of categories to show on mobile screens
        },
      },
      {
        breakpoint: 425, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1, // Number of categories to show on mobile screens
        },
      },
      {
        breakpoint: 375, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1, // Number of categories to show on mobile screens
        },
      },
      {
        breakpoint: 320, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1, // Number of categories to show on mobile screens
        },
      },
    ],
  };

  const handleNextClick = () => {
    sliderRef.current.slickNext();
  };

  const handlePrevClick = () => {
    sliderRef.current.slickPrev();
  };

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/home/header/show-all");
      setHeaderData(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/home-projects/header/show-all");
      setprojectsHeadingData(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/home/header-point/show-all");
      setHeaderPointsData(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/home/service/show-all");
      setServices(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/project/show-all");
      setProjects(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/home/brand/show-all");
      setBrands(data);
      console.log(data);
    };
    loadItems();
  }, []);

  // slider

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/home/slider/show-all");
      setHomeSlider(data);
      console.log(data);
    };
    loadItems();
  }, []);

  return (
    <>
      {/* /.header */}
      <HomeSlider images={homeSlider} />
      {/* /.header */}
      <section className="about-style-one sec-pad pb0">
        <div className="container">
          {headerData?.map((d) => (
            <div key={d._id} className="row">
              <div className="col-md-7">
                <div className="about-content">
                  <h3>
                    Welcome to <span>{d.companyName}</span>
                  </h3>
                  <p>{d.description}</p>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                      <ul className="list-items">
                        {headerPointsData
                          .slice(0, headerPointsData.length / 2)
                          .map((d) => (
                            <li key={d._id}>
                              <i className="industrio-icon-next" />{" "}
                              {d.bulletPoint}
                            </li>
                          ))}
                      </ul>
                      {/* /.list-items */}
                    </div>
                    {/* /.col-lg-4 col-md-6 col-sm-12 col-xs-12 */}
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                      <ul className="list-items">
                        {headerPointsData
                          .slice(headerPointsData.length / 2)
                          .map((d) => (
                            <li key={d._id}>
                              <i className="industrio-icon-next" />{" "}
                              {d.bulletPoint}
                            </li>
                          ))}
                      </ul>
                      {/* /.list-items */}
                    </div>
                    {/* /.col-lg-4 col-md-6 col-sm-12 col-xs-12 */}
                  </div>
                  {/* /.row */}
                  <Link to="/services" className="about-btn hvr-sweep-to-right">
                    Learn about the services
                  </Link>
                </div>
                {/* /.about-content */}
              </div>
              {/* /.col-md-7 */}
              <div className="col-md-5">
                <div className="about-img-box">
                  <img
                    className="img-fluid"
                    src={`/uploads/${d.image}`}
                    alt="Awesome Image"
                  />
                  {/* <div className="video-box">
                    <img
                      src={`/uploads/${d.image}`}
                      className="img-fluid"
                      alt="Awesome Image"
                    />
                      <a
                        href="#"
                        className="video-popup"
                      >
                        <i className="industrio-icon-play-button" />
                      </a>
                  </div> */}
                  {/* /.video-box */}
                </div>
                {/* /.about-img-box */}
              </div>
              {/* /.col-md-5 */}
            </div>
          ))}
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /.about-style-one */}
      <div className="container mt-5">
        <ServiceSlider services={services} />
      </div>
      {/* /.service-style-one */}

      <section className="portfolio-style-two sec-pad">
        <div className="container">
          {projectsheadingData.length > 0 ? (
            <div className="sec-title text-center">
              <h3>{projectsheadingData[0].heading}</h3>
              <p>{projectsheadingData[0].description}</p>
            </div>
          ) : (
            ""
          )}
          {/* /.sec-title */}
          <div className="gallery-filter">
            <div className="d-flex justify-content-center mb-2">
              <button
                className="badge bg-primary border-0 me-3"
                onClick={handlePrevClick}
              >
                &lt;
              </button>
              <button
                className="badge bg-primary border-0"
                onClick={handleNextClick}
              >
                &gt;
              </button>
            </div>
            <ul className="text-center bg-light border border-2 rounded-pill shadow-lg  ">
              <Slider ref={sliderRef} {...settings}>
                <li
                  className={`"filter ${
                    selectedCategory === "All Projects"
                      ? "active projects__category-button-bg-color rounded-pill"
                      : ""
                  }`}
                  onClick={() => filterProjects("All Projects")}
                >
                  <span
                    className={`${
                      selectedCategory === "All Projects"
                        ? "text-white"
                        : "text-dark"
                    }`}
                  >
                    <i className="industrio-icon-layers" />
                    All Projects
                  </span>
                </li>
                {/*
                 */}
                {Object.keys(groupedProjects)?.map((categoryName) => (
                  <li
                    className={
                      selectedCategory === categoryName
                        ? "active projects__category-button-bg-color rounded-pill shadow text-white"
                        : ""
                    }
                    onClick={() => filterProjects(categoryName)}
                    key={categoryName}
                  >
                    <span
                      className={`${
                        selectedCategory === categoryName
                          ? "text-white"
                          : "text-dark"
                      }`}
                    >
                      {/* <i className="industrio-icon-drop-of-liquid" /> */}
                      {categoryName.substring(0, 20)}...
                    </span>
                  </li>
                ))}
                {/*
                 */}
              </Slider>
            </ul>
            {/* /.post-filter */}
          </div>
          {/* /.gallery-filter */}
          <div className="container">
            <div className="row">
              {filteredProjects?.map((d) => (
                <div
                  className={`col-md-3 col-sm-6 col-xs-12 single-filter-item construction ${d.categoryName}`}
                  key={d._id}
                >
                  <Link to={`/project-detail/${d._id}`}>
                    <div className="single-portfolio-style-two">
                      <div className="img-box">
                        <img
                          src={`/uploads/${d.image}`}
                          alt="Awesome Image"
                          className="img-fluid"
                          style={{ height: "200px", objectFit: "cover" }}
                        />
                        <div className="overlay">
                          <div className="box">
                            <div className="content">
                              <span className="fs-3">
                                {d.title.substring(0, 40)}...
                              </span>
                              <Link to={`/project-detail/${d._id}`}>
                                <h3 className="fs-3">
                                  {d.heading.substring(0, 20)}...
                                </h3>
                              </Link>
                              <Link
                                to={`/project-detail/${d._id}`}
                                className="img-popup industrio-icon-next"
                              />
                            </div>
                            {/* /.content */}
                          </div>
                          {/* /.box */}
                        </div>
                        {/* /.overlay */}
                      </div>
                      {/* /.img-box */}
                    </div>
                    {/* /.single-portfolio-style-two */}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* /.container */}
      </section>
      {/* /.project-style-one */}

      {/* /.testimonials-style-two */}

      {/* /.feature-style-one */}

      <Support />

      {/* /.team-style-one */}

      {/* /.faq-rqa-section */}

      {/* /.blog-style-one */}

      <BrandSlider brands={brands} />
      {/* /.brand-carousel-area */}
    </>
  );
};

export default Home;
