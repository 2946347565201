import React, { useState, useEffect } from "react";
import axios from "axios";

const HistoryWork = () => {
  const [historyWork, setHistoryWorkData] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedWork, setSelectedWork] = useState(null);
  const [historyHeader, setHistoryHeaderData] = useState([]);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/about/history-work/show-all");
      setHistoryWorkData(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/about/history-header/show-all");
      setHistoryHeaderData(data);
      console.log(data);
      console.log(data[0].iconClass);
    };
    loadItems();
  }, []);

  // Function to handle the selection of a year
  const handleYearSelect = (year) => {
    setSelectedYear(year);
    const selectedWorkItem = historyWork.find((item) => item.year === year);
    setSelectedWork(selectedWorkItem);
  };

  useEffect(() => {
    if (historyWork.length > 0) {
      // sort the historyWork array by year
      const sortedHistoryWork = historyWork
        .slice()
        .sort((a, b) => a.year.localeCompare(b.year));

      // Initially, select the first year if available
      const firstYear = sortedHistoryWork[0].year;
      setSelectedYear(firstYear);
      handleYearSelect(firstYear);
    }
  }, [historyWork]);

  return (
    <>
      <section className="time-line gray-bg">
        <div className="container">
          <div className="sec-title">
            {historyHeader.length > 0 ? (
              <div className="row">
                <div className="col-md-4">
                  <h3>{historyHeader[0].title}</h3>
                </div>
                {/* /.col-md-4 */}
                <div className="col-md-8">
                  <p>{historyHeader[0].description}</p>
                </div>
                {/* /.col-md-8 */}
              </div>
            ) : (
              ""
            )}
          </div>

          <form className="timeline-tab-select-form">
            <select
              className="selectpicker"
              id="timeline-tab-select"
              value={selectedYear}
              onChange={(e) => {
                setSelectedYear(e.target.value);
                handleYearSelect(e.target.value);
              }}
            >
              {historyWork
                .slice()
                .sort((a, b) => a.year.localeCompare(b.year))
                ?.map((d) => (
                  <option key={d.year} value={d.year}>
                    {d.year}
                  </option>
                ))}
            </select>
          </form>

          <div className="tab-title-wrapper text-center">
            <div className="years-container">
              <ul className="tab-title" id="timeline-tab">
                {historyWork
                  .slice()
                  .sort((a, b) => a.year.localeCompare(b.year))
                  ?.map((d) => (
                    <li
                      key={d.year}
                      className={selectedYear === d.year ? "active" : ""}
                    >
                      <a
                        aria-controls=""
                        data-toggle="tab"
                        href="#"
                        onClick={() => handleYearSelect(d.year)}
                      >
                        {d.year}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          <div className="tab-content">
            {selectedWork && (
              <div className="tab-pane fade in active">
                <div className="row">
                  <div className="col-md-6">
                    <img
                      src={`/uploads/${selectedWork.image}`}
                      alt="Awesome Image"
                      className="img-fluid"
                      style={{ height: "338px" }}
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="content">
                      <h3>{selectedWork.title}</h3>
                      <p>{selectedWork.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default HistoryWork;
