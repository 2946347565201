import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BrandSlider = ({ brands }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Adjust as needed
    slidesToScroll: 1,
    autoplay: true, // Auto-slide enabled
    autoplaySpeed: 3000, // Set the autoplay interval (in milliseconds)
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="brand-carousel-area">
      <div className="container">
        <Slider {...settings}>
          {brands.map((brand) => (
            <div key={brand._id} className="brand-item">
              <img
                src={`/uploads/${brand.image}`}
                alt={brand.name}
                className="brand-image grayscale-image"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default BrandSlider;
