import React from "react";

const DataList = ({
  divClassName = "mb-3",
  type = "text",
  label,
  value,
  onChange,
  id,
  placeholder,
  disabled,
  defaultValue,
  accept,
  maxLength,
  pattern,
  listData,
}) => {
  return (
    <div className={divClassName}>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <input
        value={value}
        onChange={onChange}
        type={type}
        className="form-control form-control-sm"
        id={id}
        aria-describedby={id}
        placeholder={placeholder}
        disabled={disabled}
        defaultValue={defaultValue}
        accept={accept}
        maxLength={maxLength}
        pattern={pattern}
        list="datalistOptions"
      />
      <datalist id="datalistOptions">
        {listData.map((item) => (
          <option value={item}></option>
        ))}
      </datalist>
    </div>
  );
};

export default DataList;
