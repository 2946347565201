import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the CSS
import { Carousel } from "react-responsive-carousel";

const HomeSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Automatically change the slide every 5 seconds
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [images]);

  const handleChange = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="image-slider-container">
      <Carousel
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        selectedItem={currentIndex}
        onChange={handleChange}
        infiniteLoop={true}
        autoPlay={true}
        stopOnHover={true}
        interval={5000}
      >
        {images?.map((myImage, index) => (
          <div key={index} className="image-slide">
            <img src={`uploads/${myImage.image}`} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </Carousel>
      {/* <div className="slider-buttons">
        {images.map((_, index) => (
          <button
            key={index}
            className={index === currentIndex ? "active" : ""}
            onClick={() => handleChange(index)}
          />
        ))}
      </div> */}
    </div>
  );
};

export default HomeSlider;
