import React, { useState } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Input from "../../../common/Input";
import Button from "../../../common/Button";
import TextArea from "../../../common/TextArea";

const Add = () => {
  const route = "/api/service/add";
  const [userData, setUserData] = useState({
    categoryName: "",
    heading: "",
    title: "",
    description: "",
    iconClass: "",
  });

  const [image, setImage] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(false);

  const toastMessages = {
    created: "Created Successfully",
    error: "something Bad Happend",
    titleError: "Title is missing",
    descriptionError: "Description is missing",
    headingError: "Heading is missing",
    categoryNameError: "Category Name is missing",
    headingError: "Heading is missing",
    imageError: "image is missing",
    imageSizeError: "Please choose file less than 5 MB",
  };

  const notifyCreate = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  const inputHandler = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
      setFileSizeError(true);
      try {
        notifyError(toastMessages.imageSizeError);
      } catch (error) {
        console.log(error);
      }
    } else {
      setFileSizeError(false);
      setImage(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { title, heading, description, categoryName, iconClass } = userData;

    const formData = new FormData();
    formData.append("title", title);
    formData.append("heading", heading);
    formData.append("description", description);
    formData.append("categoryName", categoryName);
    formData.append("iconClass", iconClass);
    formData.append("image", image);

    if (!fileSizeError) {
      switch (true) {
        case !heading:
          notifyError(toastMessages.headingError);
          break;

        case !title:
          notifyError(toastMessages.titleError);
          break;

        case !description:
          notifyError(toastMessages.descriptionError);
          break;

        case !categoryName:
          notifyError(toastMessages.categoryNameError);
          break;

        case !image:
          notifyError(toastMessages.imageError);
          break;

        default:
          try {
            // send a POST request to the server to add the product
            const response = await axios.post(route, formData);
            notifyCreate(toastMessages.created);
            // handle the response and perform any necessary actions
            console.log(response);
            console.log(response.data);

            // reset the form
            setUserData({
              title: "",
              description: "",
              heading: "",
              categoryName: "",
              iconClass: "",
            });
          } catch (error) {
            notifyError(toastMessages.error);
            console.error(error);
          }
          break;
      }
    }
  };

  return (
    <div className="container">
      <h3 className="text-center">Add Service</h3>
      <div className="row">
        <form onSubmit={handleSubmit}>
          <tr className="d-flex justify-content-center">
            <td className="col-6">
              <Input
                value={userData.heading}
                onChange={inputHandler}
                id="Heading"
                placeholder="Heading"
                label="Heading"
                name="heading"
              />
              <Input
                value={userData.title}
                onChange={inputHandler}
                id="Title"
                placeholder="Title"
                label="Title"
                name="title"
              />
              <Input
                value={userData.categoryName}
                onChange={inputHandler}
                id="CategoryName"
                placeholder="Category Name"
                label="Category Name"
                name="categoryName"
              />
            </td>
            <span className="m-2"></span>
            <td className="col-6">
              <TextArea
                name="description"
                value={userData.description}
                onChange={inputHandler}
                id="Description"
                boxHeight="5rem"
                label="Description"
              />
              <Input
                type="file"
                onChange={handleImageChange}
                id="Image"
                placeholder="Image"
                label="Image - Please select file less than 5 MB - 666 x 645"
              />
            </td>
          </tr>
          <div className="text-center">
            <Button name="Submit" type="submit" className="btn btn-primary" />
          </div>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Add;
